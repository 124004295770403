import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';

const { EVENT_CONTACT_1STDIBS } = eventNameConstants;
const { INTERACTION_TYPE_CONTACT_SUBMITTED } = interactionTypeConstants;
const {
    STEP_INTERACTION_CHAT_INITIATED,
    STEP_INTERACTION_CHAT_BUTTON_DISPLAYED,
    STEP_INTERACTION_CHAT_MESSAGE_SENT,
    STEP_INTERACTION_CHAT_ATTACHMENT_SENT,
    STEP_INTERACTION_CHAT_END,
    STEP_INTERACTION_CHAT_TRANSCRIPT_DOWNLOAD,
    STEP_INTERACTION_CHAT_FEEDBACK_FORM_INITIATED,
    STEP_INTERACTION_CHAT_FEEDBACK_FORM_COMPLETED,
    STEP_INTERACTION_CHAT_ERROR,
} = stepInteractionConstants;

function trackChatInitiated({ placement, label }: { placement: string; label?: string }): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_INITIATED,
        trigger: `${placement}${label ? ` - ${label}` : ''}`,
    });
}

function trackChatButtonDisplayed({ placement }: { placement: string }): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_BUTTON_DISPLAYED,
        trigger: `${placement} chat button`,
    });
}

function trackMessageSent(isFirstMessage: boolean): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_MESSAGE_SENT,
        trigger: isFirstMessage ? 'first message' : 'subsequent message',
    });
}

function trackAttachmentSent(): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_ATTACHMENT_SENT,
    });
}

function trackEndChat(): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_END,
    });
}

function trackDownloadTranscript(): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_TRANSCRIPT_DOWNLOAD,
    });
}

function trackFeedbackFormInitiated(): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_FEEDBACK_FORM_INITIATED,
    });
}

function trackFeedbackFormCompleted(): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_FEEDBACK_FORM_COMPLETED,
    });
}

type ChatErrorTrigger =
    | 'authentication'
    | 'event source'
    | 'list conversations'
    | 'list conversation entries'
    | 'create conversation'
    | 'close conversation'
    | 'message'
    | 'message with attachment'
    | 'feedback form'
    | 'transcript download'
    | 'retrieve routing status';

function trackChatError({
    trigger,
    statusCode,
}: {
    trigger: ChatErrorTrigger;
    statusCode?: number;
}): void {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_ERROR,
        trigger: statusCode ? `${trigger} - ${statusCode}` : trigger,
    });
}

export {
    trackChatInitiated,
    trackChatButtonDisplayed,
    trackMessageSent,
    trackAttachmentSent,
    trackEndChat,
    trackDownloadTranscript,
    trackFeedbackFormInitiated,
    trackFeedbackFormCompleted,
    trackChatError,
};
