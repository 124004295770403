import { sessionStorage } from 'dibs-browser-storage';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';

import { isJsonObject } from './isJsonObject';
import { WEB_STORAGE_KEY } from '../constants';

import { StorageKey } from '../types';

const getItemInStorageObjByKey = (storageObj: unknown, key: string): string | undefined => {
    let item;

    if (typeof storageObj === 'object' && storageObj !== null && hasKey(storageObj, key)) {
        item = storageObj[key];
    }

    return item;
};

const initializeWebStorage = (): void => {
    const storageObj: JsonType = {};

    if (!sessionStorage.getItem(WEB_STORAGE_KEY)) {
        sessionStorage.setItem(WEB_STORAGE_KEY, storageObj);
    }
};

const getWebStorageObj = (): JsonObject | null => {
    const storage = sessionStorage.getItem(WEB_STORAGE_KEY);

    return isJsonObject(storage) ? storage : null;
};

const getItemInWebStorageByKey = (key: StorageKey): JsonType => {
    const storageObj = sessionStorage.getItem(WEB_STORAGE_KEY);

    if (storageObj) {
        if (hasKey(storageObj, key)) {
            return storageObj[key];
        }
    }

    return null;
};

const setItemInWebStorage = (key: StorageKey, value: JsonType): void => {
    const storageObj = getWebStorageObj() || {};
    storageObj[key] = value;
    sessionStorage.setItem(WEB_STORAGE_KEY, storageObj);
};

const removeItemInWebStorage = (key: StorageKey): void => {
    const storageObj = getWebStorageObj();

    if (storageObj) {
        delete storageObj[key];
        sessionStorage.setItem(WEB_STORAGE_KEY, JSON.stringify(storageObj));
    }
};

const clearWebStorage = (): void => sessionStorage.deleteItem(WEB_STORAGE_KEY);

export {
    getWebStorageObj,
    getItemInStorageObjByKey,
    initializeWebStorage,
    getItemInWebStorageByKey,
    setItemInWebStorage,
    removeItemInWebStorage,
    clearWebStorage,
};
