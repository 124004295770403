/*
these two stylesheets must be imported before any packages that import dibs-css to ensure the
correct order for DEPRECATED_GRID and dibs-css in webpack builds. 

DEPRECATED_GRID must be ordered before dibs-css to ensure dibs-css overrides grid classes.
*/
import '../scss/DEPRECATED_GRID.scss';
import 'dibs-css/exports/dibs-css.module.css';

import { combineReducers, applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

import serverVars from 'server-vars';
import { LOCALE_US } from 'dibs-intl/exports/locales';
import { clientIntlPolyfill } from '../../exports/clientIntlPolyfill';
import { VisitorTracking } from 'dibs-visit-tracking/exports/visitorTracking';
import {
    isMasqueradingAsBuyer,
    cookieJarConstants,
    getBuyerToken,
    getBuyerId,
    isDirectlyLoggedInAsUser,
} from 'dibs-cookie-jar';
import Cookies from 'js-cookie';
import { sessionStorage } from 'dibs-browser-storage';
import { getBuyerHeaderClient } from './header';
import { getBuyerFooterClient } from './footer';
import dblReducers from '../reducers';
import { getBuyerCookieNotificationClient } from './cookieNotification';
import { getBuyerSupportChatClient } from './supportChat';
import { setUserRegionalInfo } from 'dibs-regional-info/exports/setUserRegionalInfo';
import initializeTracking from '../utils/initializeTracking';
import { actionCreators } from '../actions/index';
import createRelayEnvironment from '../relayClientEnvironment';
import { triggerAuthModal } from '../utils/authModalCheck';
import { loadWidgetButtons } from '../utils/pageWidgetButtons';
import { authorizeToken } from '../helpers/authorizeToken';
import { checkHomepageUserGroup, removeUserGroupData } from '../helpers/checkHomepageGroup';
import {
    getUrlWithoutEmailToken,
    getUrlWithoutEmailLoginLinkParams,
} from '../helpers/queryParamsHelpers';
import clearVariantControlPromo from '../helpers/clearVariantControlPromos';
import { ON_SITE_REGISTRATION } from '../authModal/authentication/userStorageConstants';
import { setTradeCampaign } from '../helpers/isLoggedOutTradeHelper';
import { initCustomPerformanceReporting } from '../helpers/perfTrackingHelpers';
import initAutopilot, { AUTOPILOT_SESSION_STORAGE_KEY } from '../helpers/initAutopilot';
import initRageClickTracking from '../helpers/initRageClickTracking';
import { initChat } from 'dibs-contact-1stdibs/exports/liveChat';
import { trackMessageSent, trackEndChat } from 'dibs-contact-1stdibs/exports/liveChat/tracking';
import {
    SALESFORCE_CHAT_KEY,
    CHAT_TYPE_MAP,
} from 'dibs-contact-1stdibs/exports/liveChat/constants';
import { defaultCookieSettings } from 'dibs-cookie-jar/exports/defaultCookieSettings';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import { getUserSessionCountryCode } from 'dibs-regional-info/src/regionalInfoHelpers';
import { initPerformanceTracking } from 'dibs-tracking/src/prometheus/peformanceTracking';
import { clearSupportChatData } from 'dibs-support-chat/exports/clearSupportChatData';
import { hidePdpSellerChat } from 'dibs-support-chat/exports/hidePdpSellerChat';

import '../scss/baseBuyerStyles.scss';
import { bucketActiveABTests } from 'dibs-ab-tests/exports/clientAbTestV2';
import { AB_TEST_TYPES } from 'dibs-bucket-ab-tests';
import { disableGoogleOneTapAutoLogin } from '../helpers/googleOneTapHelpers';
import { handleGuestId } from '../utils/guestIdStorage';
const {
    USER,
    MASQUERADE_BUYER,
    MASQUERADE_BUYER_USER_TOKEN,
    USER_EMAIL,
    USER_TYPE,
    REAL_USER_TYPE,
    USER_EMAIL_TOKEN,
    DIRECT_LOGIN_ORIGINAL_USER,
} = cookieJarConstants;

const dblMiddleware = [thunk];

// will be used to store the queue so that functions exported from dbl can interact with it
let queue;

function createGlobalStore({ reducers, middlewares, state, actionSanitizer }) {
    const reducer = combineReducers({ ...reducers, ...dblReducers });
    const middleware = middlewares
        .concat(dblMiddleware)
        .filter((value, index, self) => self.indexOf(value) === index);

    let enhancer;
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension');
        enhancer = composeWithDevTools({ actionSanitizer });
    } else {
        enhancer = compose;
    }

    return createStore(reducer, state, enhancer(applyMiddleware(...middleware)));
}

Cookies.defaults = defaultCookieSettings;

function logoutFunc(redirectOverride) {
    if (isDirectlyLoggedInAsUser(document.cookie)) {
        Cookies.set(USER, Cookies.get(DIRECT_LOGIN_ORIGINAL_USER));
        Cookies.remove(DIRECT_LOGIN_ORIGINAL_USER);
        const realUserType = Cookies.get(REAL_USER_TYPE);
        if (realUserType) {
            Cookies.set(USER_TYPE, realUserType);
            Cookies.remove(REAL_USER_TYPE);
        }
    } else if (isMasqueradingAsBuyer(document.cookie)) {
        Cookies.remove(MASQUERADE_BUYER);
        Cookies.remove(MASQUERADE_BUYER_USER_TOKEN);
        /*
         * TRADE-3112
         *
         * The USER_TYPE cookie is handled differently than the masquerade
         * cookie -- the real user's type is stored in REAL_USER_TYPE, and
         * the masquerade user's type is used to set USER_TYPE.
         *
         * On ending a masqueradee session, read the REAL_USER_TYPE cookie
         * and, if present, use that to re-populate the USER_TYPE cookie.
         */
        const realUserType = Cookies.get(REAL_USER_TYPE);
        if (realUserType) {
            Cookies.remove(USER_TYPE);
            Cookies.remove(REAL_USER_TYPE);
            Cookies.set(USER_TYPE, realUserType);
        }
    } else {
        Cookies.remove(USER);
        Cookies.remove(USER_EMAIL);
        Cookies.remove(USER_TYPE);
        Cookies.remove(USER_EMAIL_TOKEN);
        handleGuestId();

        removeUserGroupData();

        const sessionId = window.Autopilot?.sessionId || window.AutopilotAnywhere?.sessionId;

        if (sessionId) {
            // this is used to reset Autopilot session so that when new user logs in new sessionId would be generated
            // eslint-disable-next-line new-cap
            window.Autopilot.GDPRCookieOptOut();
            sessionStorage.deleteItem(AUTOPILOT_SESSION_STORAGE_KEY);
        }
    }
    disableGoogleOneTapAutoLogin();
    clearVariantControlPromo();
    clearSupportChatData();
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('emailToken')) {
        location.assign(
            handleLocaleUrl(getUrlWithoutEmailToken(queryParams), GLOBAL_CLIENT_ONLY_LOCALE)
        );
    } else if (queryParams.get('email') && queryParams.get('emailLoginToken')) {
        location.replace(
            handleLocaleUrl(
                getUrlWithoutEmailLoginLinkParams(queryParams),
                GLOBAL_CLIENT_ONLY_LOCALE
            )
        );
    } else if (redirectOverride) {
        location.replace(handleLocaleUrl(redirectOverride, GLOBAL_CLIENT_ONLY_LOCALE));
    } else {
        location.reload();
    }
}

function initVisitorTracking() {
    new VisitorTracking();
}

/**
 * upon page load, we try to validate the buyer token in your cookies (userToken or emailToken)
 * if you don't have one, continue as normal
 * if you do have one, we try to look up the user associated with the token (see authorizeToken.js)
 * if the user exists, the token is valid and we continue as normal
 * if the user does not exist, the token is invalid. in this case we clear your auth cookies (logging you out), and refresh
 * NOTE: we only execute the DNE case for an explicit 401 on the userID -- see authorizeToken.js and the isUserTokenValidQuery in graphql for more detail
 * this is so that we don't accidentally log people out if our identity service is down, if graphql down, etc.
 * @param {*} relayEnv relay environment under which to make the validation query
 * @param {*} store redux store
 */
async function _buyerTokenValidityHandler(relayEnv) {
    const { userToken, emailToken } = getBuyerToken(document.cookie);
    if (emailToken && !Cookies.get(ON_SITE_REGISTRATION)) {
        try {
            window.newrelic.addPageAction('email-only|auto-logout', { emailToken });
        } catch (e) {
            // fail silently
        }
        logoutFunc();
        return;
    }
    const token = userToken || emailToken;
    if (token) {
        const isValidToken = await authorizeToken(token, relayEnv, serverVars.get('cookieDomain'));
        if (!isValidToken) {
            logoutFunc();
        }
    }
}

async function _handleHomepageHoldoutGroup(relayEnv) {
    const userId = getBuyerId(document.cookie);
    if (userId) {
        checkHomepageUserGroup(relayEnv, userId);
    }
}

/**
 * @param {object} options
 */
function _initialize(options = {}) {
    const {
        reducers = {},
        middleware = [],
        logoutUser = logoutFunc,
        state = {},
        actionSanitizer,
    } = options;

    // Buckets & fires the "ab_test_bucket" GA event for all page views
    // for all active tests of the types passed
    bucketActiveABTests([
        AB_TEST_TYPES.BUYER_USER,
        AB_TEST_TYPES.BUYER_CLIENT,
        AB_TEST_TYPES.BUYER_COOKIE,
    ]);

    if (
        state.hasOwnProperty('cart') ||
        state.hasOwnProperty('header') ||
        state.hasOwnProperty('currency')
    ) {
        throw new Error(`state cannot include reserved keys: cart, header, currency`);
    }

    // clone data with stringify/parse to ensure relay does not delete the object references when
    // passed to multiple relay environments
    const data = JSON.parse(
        JSON.stringify(serverVars.get('dbl.relayData')) ||
            serverVars.get('headerRelayPreloadedData')
    );
    const relayEnv = createRelayEnvironment(data);
    _buyerTokenValidityHandler(relayEnv);
    _handleHomepageHoldoutGroup(relayEnv);

    const { header, cart, currency } = serverVars.get('headerFooterStore') || {};

    if (!header) {
        throw new Error('Header data missing from __SERVER_VARS__.');
    }

    header.logoutUser = logoutUser;

    const store = createGlobalStore({
        reducers,
        middlewares: middleware,
        state: {
            header,
            currency,
            cart,
            ...state,
        },
        actionSanitizer,
    });
    const actions = {
        reinitTracking: () => {
            store.dispatch(actionCreators.resetTrackingFired());
        },
    };

    getBuyerHeaderClient({
        node: document.getElementById('js-header'),
        store,
        relayEnv,
    });
    getBuyerFooterClient({
        node: document.getElementById('js-footer'),
        store,
        relayEnv,
    });
    getBuyerCookieNotificationClient({
        node: document.getElementById('js-cookie-notification'),
        store,
        relayEnv,
    });
    getBuyerSupportChatClient({
        node: document.getElementById('js-support-chat'),
        relayEnv,
    });

    setUserRegionalInfo({
        relayEnv,
        cookieDomain: serverVars.get('cookieDomain'),
        userId: getBuyerId(document.cookie),
        userCountryCode: getUserSessionCountryCode(),
    });

    triggerAuthModal(relayEnv);

    initVisitorTracking();
    setTradeCampaign();

    initCustomPerformanceReporting();
    initAutopilot({
        environment: relayEnv,
        userId: getBuyerId(document.cookie),
    });
    initRageClickTracking();

    if (sessionStorage.getItem(SALESFORCE_CHAT_KEY)) {
        store.dispatch(actionCreators.setActiveLiveChatVisibility(true));
        initChat({
            chatType: CHAT_TYPE_MAP.buyer,
            environment: relayEnv,
            afterDestroy: () => {
                hidePdpSellerChat({ hide: false });
                store.dispatch(actionCreators.setActiveLiveChatVisibility(false));
            },
            onChasitorMessage: trackMessageSent,
            onChatEndedByAgent: trackEndChat,
            onChatEndedByChasitor: trackEndChat,
        });
    }

    return { store, relayEnv, actions };
}

function _initializeResponsive(options = {}, NotificationQueue, customNotificationActionTypes) {
    const {
        notifications = [],
        autoRunNotificationQueue = true,
        loadWidgetBtns = true,
        ...otherOptions
    } = options;
    const { store, actions, relayEnv } = _initialize(otherOptions);

    if (loadWidgetBtns) {
        loadWidgetButtons();
    }

    queue = new NotificationQueue(
        notifications,
        store,
        relayEnv,
        autoRunNotificationQueue,
        customNotificationActionTypes
    );
    return { store, actions };
}

function initializeDBL(options = {}) {
    initPerformanceTracking();
    const { initializers = [], customNotificationActionTypes = {}, ...otherOptions } = options;

    const locale = serverVars.get('locale') || LOCALE_US;
    initializers.push(clientIntlPolyfill(locale));

    const queuePromise = import(
        /* webpackChunkName: "NotificationQueue" */ '../notificationQueue/Queue'
    );

    return Promise.all([queuePromise, ...initializers]).then(
        ([{ default: NotificationQueue }, ...values]) => {
            const { store, actions } = _initializeResponsive(
                otherOptions,
                NotificationQueue,
                customNotificationActionTypes
            );
            const publicQueue = {
                _getNotificationQueue() {
                    return queue._getNotificationQueue();
                },
                runNotificationQueue() {
                    // Increases visit counter before re-run of queue.
                    initVisitorTracking();
                    queue.runQueue();
                },
            };

            return {
                values,
                store,
                queue: publicQueue,
                actions,
            };
        }
    );
}

function initializeDBLMobile(options = {}) {
    initPerformanceTracking();
    const { initializers = [], ...otherOptions } = options;

    const locale = serverVars.get('locale') || LOCALE_US;
    initializers.push(clientIntlPolyfill(locale));

    return Promise.all(initializers).then(values => {
        const { store, actions } = _initialize(otherOptions);
        return { values, store, actions };
    });
}

function runNotificationQueue() {
    queue.runQueue();
}

export {
    initVisitorTracking, // abc, should only be used if initializeDBL is not used
    initializeTracking, // abc, abt
    initializeDBL, // all consumers
    initializeDBLMobile, // abf
    runNotificationQueue, // abf, abm
    logoutFunc, // exported for tests, should make separate module instead
};
