import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import { trackChatInitiated as trackSupportChatInitiated } from 'dibs-support-chat/exports/tracking';
import serverVars from 'server-vars';

const GET_HELP_CATEGORY = 'Contact 1stDibs';
const CONTACT_DEALER_CATEGORY = 'contact dealer';

import { PlacementOptions } from './types';
import { Step } from './types';

const { EVENT_SELLER_FACILITATED_SUPPORT, EVENT_CONTACT_1STDIBS, EVENT_CONTACT_DEALER } =
    eventNameConstants;
const { INTERACTION_TYPE_CONTACT_SUBMITTED, INTERACTION_TYPE_CONTACT_DEALER } =
    interactionTypeConstants;
const {
    STEP_INTERACTION_COMPLETE,
    STEP_INTERACTION_CONTACT_DEALER_GET_HELP_CLICK,
    STEP_INTERACTION_CHAT_CTA_DISPLAYED,
} = stepInteractionConstants;

const getHelpEvent = ({
    label,
    action,
    trigger,
}: {
    label: string;
    action: string;
    trigger?: string;
}): void => {
    trackEvent({
        category: GET_HELP_CATEGORY,
        label,
        action,
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: action,
        step_interaction_name: label,
        trigger,
    });
};

const trackGetHelpEvent = ({
    stepInteractionName,
    trigger,
}: {
    stepInteractionName: string;
    trigger?: string;
}): void => {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: 'buyer get help modal',
        step_interaction_name: stepInteractionName,
        trigger,
    });
};

export const trackNavigateToMC = (): void =>
    trackEvent({
        category: CONTACT_DEALER_CATEGORY,
        action: 'get help contact seller click',
        eventName: EVENT_CONTACT_DEALER,
        interaction_type: INTERACTION_TYPE_CONTACT_DEALER,
        step_interaction_name: STEP_INTERACTION_CONTACT_DEALER_GET_HELP_CLICK,
    });

export const trackUserMessage = (): void =>
    trackEvent({
        category: CONTACT_DEALER_CATEGORY,
        action: 'contact dealer submitted',
        label: 'get help embedded contact',
        eventName: EVENT_CONTACT_DEALER,
        interaction_type: 'contact_dealer',
        step_interaction_name: STEP_INTERACTION_COMPLETE,
        trigger: 'get help modal',
    });

export const trackCategorySelect = (categoryKey: string): void => {
    getHelpEvent({
        action: 'category selected',
        label: categoryKey,
    });
};

export const trackTopicSelect = (topicKey: string): void => {
    getHelpEvent({
        action: 'topic selected',
        label: topicKey,
    });
};

export const trackSubmit = (topicKey: string | null): void => {
    trackEvent({
        category: GET_HELP_CATEGORY,
        action: 'submitted message',
        label: topicKey,
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: 'help_modal_message',
        trigger: topicKey || '',
    });
};

export const trackGetHelpOpened = (placement: PlacementOptions): void => {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        category: GET_HELP_CATEGORY,
        action: 'support modal opened',
        label: placement,
        interaction_type: 'buyer get help modal',
        step_interaction_name: 'Get Help link clicked',
        trigger: placement,
    });
};

export const trackChatInitiated = (topicKey: string): void => {
    trackSupportChatInitiated({
        placement: 'get help',
        label: topicKey,
    });
};

export const trackSMSClick = (topicKey: string): void => {
    trackEvent({
        category: GET_HELP_CATEGORY,
        action: 'support SMS click',
        label: topicKey,
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: 'sms',
        trigger: topicKey,
    });
};

export const trackWhatsAppClick = (topicKey: string): void => {
    trackEvent({
        category: GET_HELP_CATEGORY,
        action: 'support WhatsApp click',
        label: topicKey,
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: 'whatsapp',
        trigger: topicKey,
    });
};

export const chatCtaDisplayed = (isAgentAvailable: boolean): void => {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: INTERACTION_TYPE_CONTACT_SUBMITTED,
        step_interaction_name: STEP_INTERACTION_CHAT_CTA_DISPLAYED,
        trigger: `agents ${isAgentAvailable ? 'online' : 'offline'}`,
    });
};

export const trackContactSeller = (topicKey?: string): void => {
    trackEvent({
        category: GET_HELP_CATEGORY,
        action: 'get help contact seller',
        label: topicKey || '',
    });
};

export const trackPrimaryCtaClick = (topicKey: string): void => {
    getHelpEvent({
        action: 'primary CTA click',
        label: topicKey,
    });
};

export const trackSupportCenterClick = (topicKey: string): void => {
    getHelpEvent({
        action: 'buyer get help modal',
        label: 'Navigate to support center',
        trigger: topicKey,
    });
};

export const trackGoBackNav = (step: Step): void => {
    getHelpEvent({
        action: 'go back',
        label: step,
    });
};

export const existingChatLoaded = (): void => {
    const pageType = serverVars.get('settings.pageType');
    getHelpEvent({
        action: 'existing chat loaded',
        label: pageType,
    });
};

export const trackHelpWithAnOrderClick = (): void => {
    getHelpEvent({
        action: 'buyer get help modal',
        label: 'Help with an order clicked',
    });
};

export const trackOtherAssistanceSelected = (): void => {
    trackGetHelpEvent({
        stepInteractionName: 'Other assistance selected',
    });
};

export const trackContactOptionsExpanded = (topicKey?: string): void => {
    trackEvent({
        category: GET_HELP_CATEGORY,
        action: 'contact options expanded',
        label: topicKey || '',
    });
};

export const trackContactSellerFormSubmitted = (): void => {
    trackEvent({
        eventName: EVENT_SELLER_FACILITATED_SUPPORT,
        category: 'seller facilitated support',
        action: 'support request submitted',
        eventAction: 'support request submitted',
        label: 'contactOrderSeller',
        interaction_type: 'support request submitted',
        step_interaction_name: 'support_request_submitted',
    });
};

export const trackModalClosed = (): void => {
    trackGetHelpEvent({
        stepInteractionName: 'Get Help Modal Closed',
    });
};

export const trackOrderSelected = (): void => {
    trackGetHelpEvent({
        stepInteractionName: 'Order Selected',
    });
};
